<template>
  <v-expansion-panel v-model="panel">

    <v-expansion-panel-header class="mt-2">
      AÑADIR TRASPASO
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="num++" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="cleanDataBeforeClose()" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <AddTransferC :win="win" :key="num"/>
    </v-expansion-panel-content>

  </v-expansion-panel>
</template>

<script>
import AddTransferC from '../partial/AddTransferC.vue'

export default {
  name: "AddTransfer",

  components: {
    AddTransferC
  },

  props: ['win'],

  data() {
    return {
      num: 0,
      panel: 0,
    }
  },

  methods: {
    cleanDataBeforeClose() {
      this.$store.dispatch('deleteWindowByUuid', { uuid: this.win.uuid })
      this.$store.commit('transferData/setTransfers', [])
    },
  },
}
</script>

<style scoped>
.fs-10 {
  font-size: 10px !important;
}

.input-group-prepend {
  display: flex;
}

.input-group-prepend {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 3px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0 5px;
  font-size: 14px;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group>.form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.tabs {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #F29D35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;

}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;

}

.tabsB:hover {
  background-color: #0d47a1;
  cursor: pointer;
}

.tabsN {
  padding: 5px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
  border-radius: 15px;
}

.icon-color {
  color: white
}
</style>